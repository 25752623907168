.login-page-container {
  display: flex;
  justify-content: center;
  width: 100% !important;
  .login-form {
    width: 100% !important;
  }
}
.login-page-icon-container {
  display: flex;
  justify-content: center;
  padding: 1em;
  .login-page-icon {
    width: 60%;
    height: 60%;
    user-select: none;
  }
}
