.content {
  margin-left: 16em;
  margin-top: -4em;
}

.coverPic {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50% 50% 50% 50%;
}

.colPadding {
  padding: 1em;
}

.recicleBin {
  position: absolute;
  padding: 1em;
  right: 1em;
}

.searchPadding {
  padding: 8px;
  width: 20em;
}

.searchInput {
  margin-bottom: 8px;
  display: block;
}

.searchButton {
  width: 90px;
}

.title {
  font-size: 2rem;
  color: #00a49a;
}

.bookBooktypesFormStyle {
  display: inline-block;
}

.coverPicPreview {
  width: 100%;
}

.bookKeywordsTransferText {
  text-align: left;
}

.forgotpasswordEmailPositioning {
  padding: 0px 1em 2em 1em;
  text-align: center;
}

.forgotpasswordErrorMessage {
  color: #182d71;
  font-size: 2.5em;
  font-weight: bolder;
  text-align: center;
  padding: 1em;
}

.loginPagePadding {
  padding-top: 100px;
}

.loginPageLogoPositioning {
  margin: 0px auto;
  width: 20%;
}

.allrightsreservedStyle {
  margin: 0px auto;
  width: 20%;
  text-align: center;
  bottom: 0px;
}

.successPageMessage {
  text-align: center;
  color: #182d71;
  font-size: 2.7vw;
  padding: 1em;
}

.audioRecicleBinButton {
  position: absolute;
  left: 9em;
  bottom: 5em;
}

.deletedModal {
  padding: 1em;
  position: absolute;
  right: 1em;
}

.deletedPictureStyle {
  height: 150px;
}

.pictureCardStyle {
  margin: 15px;
  max-width: 200px;
}

.loadingButton {
  display: block;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}

.picturePaginator {
  position: relative;
  margin-left: calc(50% - 10vw);
}

.picturePreview {
  max-width: 70em;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pictureStyle {
  height: 250px;
}

.xlsxImportText {
  text-align: center;
}

.questionAnswerImage {
  max-width: 10em;
  max-height: 14em;
}

.textImage {
  max-height: 10em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
